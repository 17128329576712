import React from 'react';
import { Box } from 'rebass';
import theme from '../layouts/theme';
import { phone, tablet, laptop } from '../layouts/media';
import styled, { css } from 'styled-components';
import { H5, TextBody2, Underline } from '../ui';

const Features = () => (
  <Grid mb={[6, 7]}>
    <Feature title="Alimentação">
      Encontra inspiração nos hábitos alimentares de pessoas em excelente forma
      física.
    </Feature>
    <Feature title="Treino">
      Descobre o estilo de treinos que têm os nossos <i>Gymlovers</i>.
    </Feature>
    <Feature title="Motivação">
      Descobre o que motiva os nossos <i>Gymlovers</i> a ter um estilo de vida
      saudável e inspira-te a fazer o mesmo.
    </Feature>
  </Grid>
);

export default Features;

const Feature = ({ title, children }) => (
  <Box>
    <H5 my={0}>{title}</H5>
    <Underline my={[2, 3]} />
    <TextBody2 fontFamily={theme.fonts.family}>{children}</TextBody2>
  </Box>
);

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 194px);
  grid-column-gap: 58px;
  justify-content: center;

  ${laptop(css`
    grid-template-columns: repeat(3, 164px);
    grid-column-gap: 28px;
  `)};

  ${tablet(css`
    grid-template-columns: repeat(2, 256px);
    grid-column-gap: 54px;
    grid-row-gap: 54px;
  `)};

  ${phone(css`
    grid-template-columns: 1fr;
    grid-row-gap: 48px;

    margin-top: 42px;
  `)};
`;
