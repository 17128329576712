import React from 'react';
import { Box } from 'rebass';
import { H2 } from '../ui';

const About = () => (
  <Box mt={['88px', '188px']} mb={['88px', '108px', '112px']}>
    <H2 fontWeight={500} textAlign="center">
      Somos uma comunidade que celebra os atletas do dia-a-dia.
      <span role="img" alt="fist emoji" aria-label="fist emoji">
        👊
      </span>
    </H2>
  </Box>
);

export default About;
