import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Box } from 'rebass';
import { MainCard, H3, TextBody1, LinkNoStyles, Button } from '../ui';
import { phone, tablet, laptop } from '../layouts/media';
import { fadeIn } from 'react-animations';
import { GatsbyImage } from "gatsby-plugin-image";

class Hero extends React.Component {
  render() {
    const { post, image } = this.props;

    return (
      <Box mb={[4, 4, 4, 4, 5, 5]}>
        <Grid>
          <Image image={image.childImageSharp.gatsbyImageData} backgroundColor={post.frontmatter.color} />
          <Card
            ml={[0, 0, '-16px', -3, -4]}
            px={[0, 0, 4, 4, 5, 6]}
            py={[0, 0, 5, 5, 5, 6]}
            minWidth={[0, 0, '420px', '620px', '620px', '780px']}
          >
            <H3 fontWeight={500}>{post.frontmatter.title}</H3>
            <TextBody1 dangerouslySetInnerHTML={{ __html: post.frontmatter.description }}/>
            <Box my={4}>
              <LinkNoStyles to={post.fields.slug}>
                <Button>Ver entrevista</Button>
              </LinkNoStyles>
            </Box>
          </Card>
        </Grid>
        <MainCard post={post} image={image} />
      </Box>
    );
  }
}

export default Hero;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  ${phone(css`
    display: none;
  `)}; };
`;

const fadeInAnimation = keyframes`${fadeIn}`;

const Image = styled(GatsbyImage)`
  object-position-x: 20%;
  min-height: 742px;
  height: 87vh;
  border-radius: 0 24px 24px 0;

  animation: 1.5s ${fadeInAnimation};

  & > picture {
    & > img {
      object-position: 42% 12% !important;
      border-radius: 0 24px 24px 0;

      ${laptop(css`
        object-position: 58% 12% !important;
        border-radius: 0 24px 24px 0;
      `)};
    }
  }

  ${laptop(css`
    min-width: 480px;
    min-height: 680px;
    height: 760px;
    object-position-x: 50%;
  `)};

  ${tablet(css`
    height: 560px;
  `)};
`;

const Card = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  max-width: 634px;
  max-height: 640px;

  z-index: 2;

  background: ${props => props.theme.colors.lightBlue};

  border-radius: 24px;

  ${laptop(css`
    max-width: 472px;
  `)};
`;
