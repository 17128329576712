import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Flex, Box } from "rebass";

import { randomNumber } from "../utils/helpers";
import { Container, Button, LinkNoStyles } from "../ui";

import Layout from "../components/layout";
import InterviewsCardsGrid from "../components/InterviewsCardsGrid";
import Hero from "../components/Hero";
import About from "../components/About";
import Features from "../components/Features";

import { getPostsForScreenSize } from "../components/Utilities";

export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: allPosts } = data.allMarkdownRemark;
    let randomInterviewIndex = randomNumber(allPosts.length);

    const mainPost = allPosts.slice(0, 1)[0];
    let posts = getPostsForScreenSize(allPosts.slice(1, 4));

    return (
      <Layout
        location={this.props.location}
        interview={allPosts[randomInterviewIndex]}
      >
        <Hero
          post={mainPost.node}
          image={mainPost.node.frontmatter.featuredImage}
        />
        <Container>
          <Box my={[4, 5]}>
            <InterviewsCardsGrid posts={posts} />
            <Flex justifyContent="center" my={5}>
              <LinkNoStyles to="/entrevistas">
                <Button secondary="true">Ver mais entrevistas</Button>
              </LinkNoStyles>
            </Flex>
          </Box>
          <About />
          <Features />
        </Container>
      </Layout>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "interview-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 180)
          id
          fields {
            slug
          }
          frontmatter {
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            templateKey
            description
            color
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
